import React from "react";
import "./DmagafySection.css";

const DmagafySection: React.FC = () => {

  const muskIcon = "https://www.dropbox.com/scl/fi/st2aarpcij4hhkwi6p7qg/1.jpeg?rlkey=jm5kany8mlwun7dtm02hr4nww&st=1e02vmbg&dl=1";
  const trumpIcon1 = "https://www.dropbox.com/scl/fi/98d61nm5y92q5487nvg9l/2.jpeg?rlkey=svwkuhqo4wf0w21pe4040q8yv&st=arm9b332&dl=1";
  const gigaIcon = "https://www.dropbox.com/scl/fi/joyrdrw8xafga7nb2ye4q/3.jpeg?rlkey=3jnjruy4joq4mociohcgmw6u7&st=0t4tywql&dl=1";
  const trumpIcon2 = "https://www.dropbox.com/scl/fi/p4ufn9q1nl718iwa1hj5z/4.jpeg?rlkey=df87r3tkeyws5uqrfq8zd1cg9&st=5hr32sw2&dl=1";
  const backgroundImage = "https://www.dropbox.com/scl/fi/l78h5f235fq8hxspcw1ob/trump.png?rlkey=hts1vjq5lzomj1bwki3kg0r7o&st=devgmv3k&dl=1";


  return (
    <section className="dmagafy-section" id="dmagafy-section" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="dmagafy-row-1"></div>
      <div className="dmagafy-row-2">
        <img src={muskIcon} alt="Musk" />
        <img src={trumpIcon1} alt="Trump1" />
        <img src={gigaIcon} alt="Giga" />
        <img src={trumpIcon2} alt="Trump2" />
      </div>
      <div className="dmagafy-row-3">
        <h1>
          <span>DARKMAGA</span> PFP
        </h1>
        <p className="call-to-action">CREATE YOUR OWN!</p>
      </div>
      <div className="dmagafy-row-4">
        <p>Join the Dark Patriots and <span>DMAGAfy</span> any picture.</p>
      </div>
      <div className="dmagafy-row-5">
        <p>
        Simply upload and edit, download and share!
        </p>
      </div>
      <div className="dmagafy-row-6">
        <a
          href="https://dmagafy.netlify.app/"
          rel="noreferrer"
          target="_blank"
          className="dmagafy-button"
        >
          Get your DMAGA pfp and pictures
        </a>
      </div>
    </section>
  );
};

export default DmagafySection;
