import React, { useState,  useRef } from "react";
import "./HeroSection.css";
import soundIcon from "../../../../assets/images/volume.png";
import muteIcon from "../../../../assets/images/mute.png"

const HeroSection: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isMuted, setIsMuted] = useState(true);

  const toggleSound = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };


  return (
    <section className="hero-section">
      <div className="hero-banner">
        <video
          ref={videoRef}
          src="https://www.dropbox.com/scl/fi/yj2lacav5x714f1cw8ha5/heroBg.mp4?rlkey=sqxnsxa903caxc91uzgee47s8&st=2yhup6px&dl=1"
          className="hero-banner-video"
          autoPlay
          loop
          playsInline
          preload="auto"
          controls={false}
          webkit-playsinline=""
          x5-playsinline
          muted = {isMuted}
        />
      </div>
      <div className="hero-container">
        <div className="row-1">
          
          <button className="sound-button-1" onClick={toggleSound}>
            {isMuted ? (
              <img src={muteIcon} alt="Mute" className="sound-off" />
            ) : (
              <img src={soundIcon} alt="Sound" className="sound-on" />
            )}
          </button>
        
        </div>
        <div className="row-2">
          <h1>$dmaga</h1>
        </div>
        <div className="row-3">
          <h1>darkmaga</h1>
        </div>
        <div className="row-4">
          <h3>dark maga patriots in control</h3>
        </div>
        <div className="row-6">
          <a href="#guide-section" className="hero-buy-button">
            BUY DARK MAGA
          </a>
          <a href="#dmagafy-section" className="hero-dmagafy-button">CREATE YOUR DARK MAGA PICTURE</a>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
